import React, {Component,Fragment} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import axios from "axios";
import ApiURL from "../../api/AppURL";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class FooterMobile extends Component {

    constructor(){
        super();
        this.state={
            androidLink:"",
            iosLink:"",
            facebookLink:"",
            twitterLink:"",
            instagramLink:"",
            address:"",
            aboutCompany:"",
            deliveryNotice:"",
            loaderDiv:"",
            mainDiv:"d-none"
        }
    }

    componentDidMount(){
        let SiteInfoFooter = sessionStorage.getItem("SiteInfoFooter");

        if(SiteInfoFooter==null){
            axios.get(ApiURL.SendSiteInfo).then(response=> {

                let StatusCode = response.status;

                if(StatusCode==200){
                    let JSONData = (response.data)[0];
                    this.setState({
                        androidLink:JSONData['android_app_link'],
                        iosLink:JSONData['ios_app_link'],
                        facebookLink:JSONData['facebook_link'],
                        twitterLink:JSONData['twitter_link'],
                        instagramLink:JSONData['instagram_link'],
                        address:JSONData['address'],
                        aboutCompany:JSONData['about_company'],
                        deliveryNotice:JSONData['delivery_notice'],
                        loaderDiv:"d-none",
                        mainDiv:""
                    })

                    sessionStorage.setItem("SiteInfoFooter",JSON.stringify(JSONData))
                }
                else{
                    toast.error("Something Went Wrong! Try Again",{
                        position:"bottom-center"
                    });
                }

            }).catch(error=> {
                toast.error("Something Went Wrong! Try Again",{
                    position:"bottom-center"
                });
            });
        } else {
            let FooterDataJSON=JSON.parse(SiteInfoFooter);
            this.setState({
                androidLink:FooterDataJSON['android_app_link'],
                iosLink:FooterDataJSON['ios_app_link'],
                facebookLink:FooterDataJSON['facebook_link'],
                twitterLink:FooterDataJSON['twitter_link'],
                instagramLink:FooterDataJSON['instagram_link'],
                address:FooterDataJSON['address'],
                aboutCompany:FooterDataJSON['about_company'],
                deliveryNotice:FooterDataJSON['delivery_notice'],
                loaderDiv:"d-none",
                mainDiv:""
            })
        }
    }


    render() {
        return (
            <div className="m-0 bg-white mt-5 pt-1 shadow-sm">
                <Container>
                    <Row className="px-0 my-5">
                        <Col sm={6} xm={6}>
                            <h5 className="footer-menu-title mt-3">OFFICE ADDRESS</h5>
                            <p>{ ReactHtmlParser(this.state.address) }</p>
                        </Col>
                        <Col sm={6} xm={6}>
                            <h5 className="footer-menu-title">SOCIAL LINK</h5>
                            <a href={this.state.facebookLink}><i className="fab m-1 h4 fa-facebook"></i></a>
                            <a href={this.state.instagramLink}><i className="fab m-1 h4 fa-instagram"></i></a>
                            <a href={this.state.twitterLink}><i className="fab m-1 h4 fa-twitter"></i></a>

                        </Col>
                        <Col sm={6} xm={6}>
                            <h5 className="footer-menu-title">MORE...</h5>
                            <Link to="/about" className="footer-link">About Us</Link><br/>
                            <Link to="/contact" className="footer-link">Contact Us</Link><br/>
                            <Link to="/purchase" className="footer-link">How To Purchase</Link><br/>
                            <Link to="/policy" className="footer-link">Privacy Policy</Link><br/>
                            <Link  to="/refund" className="footer-link">Refund Policy</Link><br/>

                        </Col>
                        <p className="mt-3">Change Language</p>
                        <p className="mt-1" id="google_translate_element"></p>

                    </Row>
                </Container>
                <Container fluid={true} className=" m-0 pt-3 pb-1 bg-dark">
                    <Container className="">
                        <Row className="px-0">
                            <h6 className="text-white">WE DELIVER IN</h6>
                            <p className="footer-text text-white">{ ReactHtmlParser(this.state.deliveryNotice) } </p>
                        </Row>
                    </Container>
                </Container>

                <ToastContainer />
            </div>

        );
    }
}

export default FooterMobile;