class ApiURL{
    static BaseURL = "https://ecom-admin.jswebspace.co.uk/api/";
    static VisitorDetails = this.BaseURL+"SendVisitorDetails";
    static SendContactDetails = this.BaseURL+"SendContactDetails";
    static SendSiteInfo = this.BaseURL+"SendSiteInfo";
    static SendCategoryDetails=this.BaseURL+"SendCategoryDetails";


    static ProductListByRemark(Remark){
      return this.BaseURL+"ProductListByRemark/"+Remark;
    };

    static ProductListByCategory(Category){
        return this.BaseURL+"ProductListByCategory/"+Category;
    };

    static ProductListBySubCategory(Category, SubCategory){
        return this.BaseURL+"ProductListBySubCategory/"+Category+"/"+SubCategory;
    };

    static SendSliderInfo=this.BaseURL+"SendSliderInfo";

    static ProductDetails(ProductCode){
        return this.BaseURL+"ProductDetails/"+ProductCode;
    };

}

export default ApiURL;