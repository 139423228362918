import React, {Component,Fragment} from 'react';
import {Card,Modal,Button,Container,Row,Col} from "react-bootstrap";

class Notification extends Component{
    constructor(){
        super();
        this.state={
            show:false,
        }
    }

    handleClose = () => {
        this.setState({show:false})
    };
    handleShow = () =>{
      this.setState({show:true})
    };

    render(){
        return(
            <Fragment>
                <Container className="TopSection">
                    <Row>
                        <Col className="p-1" md={6} lg={6} sm={12} xs={12}>
                            <Card onClick={this.handleShow} className="notification-card">
                                <Card.Body>
                                    <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry</h6>
                                    <p className="py-1 px-0 text-primary m-0"><i className="fa fa-bell"></i> Date:22/12/2010 | Status:Unread</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="p-1" md={6} lg={6} sm={12} xs={12}>
                            <Card onClick={this.handleShow} className="notification-card">
                                <Card.Body>
                                    <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry</h6>
                                    <p className="py-1 px-0 text-primary m-0"><i className="fa fa-bell"></i> Date:22/12/2010 | Status:Unread</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="p-1" md={6} lg={6} sm={12} xs={12}>
                            <Card onClick={this.handleShow} className="notification-card">
                                <Card.Body>
                                    <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry</h6>
                                    <p className="py-1 px-0 text-primary m-0"><i className="fa fa-bell"></i> Date:22/12/2010 | Status:Unread</p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="p-1" md={6} lg={6} sm={12} xs={12}>
                            <Card onClick={this.handleShow} className="notification-card">
                                <Card.Body>
                                    <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry</h6>
                                    <p className="py-1 px-0 text-primary m-0"><i className="fa fa-bell"></i> Date:22/12/2010 | Status:Unread</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header CloseButton>
                        <h6><i className="fa fa-bell"></i> Date: 22/12/2010 </h6>
                    </Modal.Header>
                    <Modal.Body>
                         <h6>Lorem Ipsum is simply dummy text of the printing and typesetting industry</h6>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industryLorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industryLorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        );
    }
}

export default Notification;