import React, {Component,Fragment} from 'react';
import {Container,Row,Col,Card,Button} from 'react-bootstrap';
import {Link} from "react-router-dom";
class Favourite extends Component{
    render() {
        return (
            <Fragment>
                <Container className="text-center bg-white card-body shadow-sm py-5 BetweenTwoSection" fluid={true}>
                    <h4 className="section-title">My Favourite Items</h4>
                    <h6 className="section-sub-title">Some Our Exclusive Collection, You May Like</h6>
                    <Row>

                        <Col className="p-1" key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                            <Link to="/productDetails">
                                <Card className="card text-center w-100 image-box ">
                                    <img src="Images/product2.jpg"/>
                                    <Card.Body>
                                        <h5 className="product-name-on-card">ASUS TUF A15 FA506IU Ryzen 7 4800H GTX</h5>
                                        <p className="product-price-on-card">Price: 3000TK</p>
                                        <Button className="btn btn-sm site-btn"><i className="fa fa-trash-alt"></i> Remove </Button>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col className="p-1" key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                            <Link to="/productDetails">
                                <Card className="card text-center w-100 image-box ">
                                    <img src="Images/product2.jpg"/>
                                    <Card.Body>
                                        <h5 className="product-name-on-card">ASUS TUF A15 FA506IU Ryzen 7 4800H GTX</h5>
                                        <p className="product-price-on-card">Price: 3000TK</p>
                                        <Button className="btn btn-sm site-btn"><i className="fa fa-trash-alt"></i> Remove </Button>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>

                        <Col className="p-1" key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                            <Link to="/productDetails">
                                <Card className="card text-center w-100 image-box ">
                                    <img src="Images/product2.jpg"/>
                                    <Card.Body>
                                        <h5 className="product-name-on-card">ASUS TUF A15 FA506IU Ryzen 7 4800H GTX</h5>
                                        <p className="product-price-on-card">Price: 3000TK</p>
                                        <Button className="btn btn-sm site-btn"><i className="fa fa-trash-alt"></i> Remove </Button>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>
                        <Col className="p-1" key={1} xl={3} lg={3} md={3} sm={6} xs={6} >
                            <Link to="/productDetails">
                                <Card className="card text-center w-100 image-box ">
                                    <img src="Images/product2.jpg"/>
                                    <Card.Body>
                                        <h5 className="product-name-on-card">ASUS TUF A15 FA506IU Ryzen 7 4800H GTX</h5>
                                        <p className="product-price-on-card">Price: 3000TK</p>
                                        <Button className="btn btn-sm site-btn"><i className="fa fa-trash-alt"></i> Remove </Button>
                                    </Card.Body>
                                </Card>
                            </Link>
                        </Col>




                    </Row>
                </Container>
            </Fragment>

        );
    }
}


export default Favourite;