import React,{Component,Fragment} from 'react';
import NavMenuDesktop from "../components/common/NavMenuDesktop";
import NavMenuMobile from "../components/common/NavMenuMobile";
import FooterDesktop from "../components/common/FooterDesktop";
import FooterMobile from "../components/common/FooterMobile";
import ProductDetails from "../components/ProductDetails/ProductDetails";
import SuggestedProducts from "../components/ProductDetails/SuggestedProducts";
import axios from "axios";
import ApiURL from "../api/AppURL";
import SliderLoader from "../components/placeholder/SliderLoader";

class ProductDetailsPage extends Component{

    constructor({match}){
        super();
        this.state={
            code:match.params.code,
            ProductData:[],
            isLoading:"BetweenTwoSection",
            MainDiv:"d-none"
        }
    }

    componentDidMount() {
        window.scroll(0,0);

        axios.get(ApiURL.ProductDetails(this.state.code)).then(response=>{
            this.setState({ProductData:response.data,isLoading:"d-none",MainDiv:" "})
        }).catch(error=>{

        });
    }

    render(){

            if( this.state. MainDiv=="d-none"){

                return(
                    <Fragment>

                        <div className="Desktop">
                            <NavMenuDesktop/>
                        </div>

                        <div className="Mobile">
                            <NavMenuMobile/>
                        </div>

                       <SliderLoader isLoading={this.state.isLoading} />

                        <SuggestedProducts/>

                        <div  className="Desktop">
                            <FooterDesktop/>
                        </div>

                        <div  className="Mobile">
                            <FooterMobile/>
                        </div>

                    </Fragment>
                )

            }else{

                return(
                    <Fragment>

                        <div className="Desktop">
                            <NavMenuDesktop/>
                        </div>

                        <div className="Mobile">
                            <NavMenuMobile/>
                        </div>

                       <ProductDetails ProductData={this.state.ProductData}/>

                        <SuggestedProducts/>

                        <div  className="Desktop">
                            <FooterDesktop/>
                        </div>

                        <div  className="Mobile">
                            <FooterMobile/>
                        </div>

                    </Fragment>
                )


            }

    }
}

export default ProductDetailsPage;