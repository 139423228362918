import React, {Component,Fragment} from 'react';
import Slider from "react-slick";
import {Link} from "react-router-dom";
class SliderHome extends Component {
    render() {

        const settings = {
            dots: true,
            infinite: true,
            autoplay:true,
            autoplaySpeed:3000,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        const SliderData =this.props.data;

        const SliderView = SliderData.map((SliderList,i)=>{

            return  <div className="container-fluid overflow-hidden w-100 shadow-sm">

                <div style={{backgroundColor:SliderList.bg_color}}>

                    <div className="row card-body">
                        <div className="col-md-6 sliderTitleDiv text-center">
                            <h1 style={{color:SliderList.text_color}} className="sliderTitle">{SliderList.title}</h1>
                            <h1 style={{color:SliderList.text_color}} className="sliderSubTitle text-white">
                                {SliderList.sub_title}
                            </h1>
                            <Link to={"ProductDetails/"+SliderList.product_code} className="btn site-btn px-5">More Info</Link>
                        </div>
                        <div className="col-md-6 text-center">
                            <img className="sliderImg" src={SliderList.image}/>
                        </div>
                    </div>

                </div>

            </div>
        })

        return (
                <Slider {...settings}>

                    {SliderView}

                </Slider>
        );
    }
}

export default SliderHome;