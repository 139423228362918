import React, {Component, Fragment} from 'react';
import {Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

class ListBySubCategory extends Component {

    render() {

        const MyList=this.props.ProductData;
        const SubCategory = this.props.SubCategory;
        const MyView= MyList.map((ProductList,i)=>{

            if(ProductList.special_price=="NA"){

                return <Col key={i.toString()} className="p-1" xl={2} lg={2} md={2} sm={4} xs={6} >
                    <Link to={"/ProductDetails/"+ProductList.product_code}>
                        <Card className="card w-100 image-box ">
                            <img src={ProductList.image}/>
                            <Card.Body>
                                <h5 className="product-name-on-card">{ProductList.title}</h5>
                                <p className="product-price-on-card">Price: {ProductList.price}TK</p>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>

            }
            else{

                return <Col key={i.toString()} className="p-1" xl={2} lg={2} md={2} sm={4} xs={6} >
                    <Link to={"/ProductDetails/"+ProductList.product_code}>
                        <Card className="card w-100 image-box ">
                            <img src={ProductList.image}/>
                            <Card.Body>
                                <h5 className="product-name-on-card">{ProductList.title}</h5>
                                <p className="product-price-on-card">Price: <strike className="text-secondary">{ProductList.price}TK</strike>
                                    {ProductList.special_price}TK
                                </p>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>

            }

        })


        return (
            <Fragment>
                <Container className="text-center TopSection" fluid={true}>
                    <h4 className="section-title"> {SubCategory}</h4>
                    <Row>
                        {MyView}
                    </Row>
                </Container>
            </Fragment>
        );
    }
}

export default ListBySubCategory;