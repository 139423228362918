import React, {Component, Fragment} from 'react';
import {Card, Col, Container, Row} from "react-bootstrap";
import axios from "axios";
import ApiURL from "../../api/AppURL";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Refund extends Component {

    constructor(){
        super();
        this.state={
            terms:"",
            loaderDiv:"",
            mainDiv:"d-none"
        }
    }


    componentDidMount(){

        let SiteInfoTerms = sessionStorage.getItem("SiteInfoTerms");

        if(SiteInfoTerms==null){
            axios.get(ApiURL.SendSiteInfo).then(response=> {

                let StatusCode = response.status;

                if(StatusCode==200){
                    let JSONData = (response.data)[0]['terms'];
                    this.setState({terms:JSONData,loaderDiv:"d-none",mainDiv:""})

                    sessionStorage.setItem("SiteInfoTerms",JSONData)
                }
                else{
                    toast.error("Something Went Wrong! Try Again",{
                        position:"bottom-center"
                    });
                }

            }).catch(error=> {
                toast.error("Something Went Wrong! Try Again",{
                    position:"bottom-center"
                });
            });
        } else {
            this.setState({terms:SiteInfoTerms,loaderDiv:"d-none",mainDiv:""})
        }

    }

    render() {
        return (
            <Fragment>
                <Container className="TopSection">
                    <Row>
                        <Col className="mt-2" md={12} lg={12} sm={12} xs={12}>
                            <Card className={this.state.loaderDiv}>
                                <Card.Body>
                                    <div class="ph-item">
                                        <div class="ph-col-12">
                                            <div class="ph-row">
                                                <div class="ph-col-12"></div>
                                                <div class="ph-col-12"></div>
                                                <div class="ph-col-12"></div>
                                                <div class="ph-col-12"></div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            <Card className={this.state.mainDiv}>
                                <Card.Body>
                                    { ReactHtmlParser(this.state.terms) }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <ToastContainer />
            </Fragment>
        );
    }
}

export default Refund;